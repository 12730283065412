<template>
  <v-container fill-height class="webform">
     <v-row>
       <v-col md="6"  class="mx-auto text-center my-5">
          <img src="https://sorteo.acpblindaje.mx/logo_acp.jpg" alt="" style="max-width:200px;">
           <v-spacer></v-spacer>
       </v-col>
     </v-row>
    <v-row class="text-center " >
    <v-col md="6" class="mx-auto">
         <v-subheader>Por favor ingrese los datos requeridos con * </v-subheader>
      </v-col>

    </v-row>
    <v-row  class="text-center  ">
      <v-col md="6" class="mx-auto">
        <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="name"
      :counter="10"
      :rules="nameRules"
      label="Nombre * "
      required
    ></v-text-field>
 <v-text-field
      v-model="phone"
      :rules="phoneRules"
      label="Telefono *"
      required
    ></v-text-field>
    <v-text-field
      v-model="email"
       
      label="E-mail"
      required
    ></v-text-field>

 

    <v-checkbox
      v-model="checkbox"
      :rules="[v => !!v || 'Por favor acepte los terminos']"
      label="Estoy de acuerdo en los terminos"
      required
    ></v-checkbox>

    <v-btn
       block

      :disabled="!valid"
      color="success"
      class="my-2"
      @click="validate"

    >
      Registrarse

       <template v-slot:loader>
        <span>Loading...</span>
      </template>
    </v-btn>

    <v-btn
      block
      color="error"
      class="my-2"
      @click="reset"
    >
      Borrar
    </v-btn>


 <v-overlay :value="overlay"></v-overlay>

  <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        text
         block
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          class="my-2"
        >
          Aviso de Privacidad
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <h3 class="text-h5  text-center">Aviso de Privacidad</h3>
        </v-card-title>
        <v-card-text class="text-center">
         <h4>Términos y Condiciones del Aviso de Privacidad</h4>
 <hr class="my-2">
<p>ACP Blindaje, con domicilio en Av. Adolfo López Mateos Sur 540, Jardines de Los Arcos, 44500 Guadalajara, Jal., en cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, emite el presente Aviso de Privacidad y te informa lo siguiente:</p>

<h4>¿Para qué fines utilizaremos tus datos personales?</h4>
 <hr class="my-2">
<p>Los datos personales que recabamos al completar formularios en línea en nuestro sitio web o al contactarnos vía correo electrónico, los utilizaremos para las siguientes finalidades que son necesarias para brindarte el servicio:</p>

<p>Para verificar y confirmar tu identidad y verificar al ganador de nuestro sorteo. <br>
Para llevar a cabo el registro correspondiente al sorteo ACP.</p>
  
 
<h4>¿Qué datos personales utilizaremos para estos fines?</h4>
 <hr class="my-2">
<p>Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad, utilizaremos datos personales de nombre, telefono e email.</p>

 

<p>Esta información será utilizada únicamente en nuestro Sorteo y sin compartir con nadie mas.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
          
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            De Acuerdo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
  </v-form>



 <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
 



   </v-container>
 


</template>

<script>
import axios from "axios";
  export default {
    name: 'Webform',

   data: () => ({


    userdata:[],

    //Overlay
     overlay: false,
    //dialog
     dialog:false,
    //Snackbar
       multiLine: true,
      snackbar: false,
      text: `I'm a multi-line snackbar.`,


      //loader 
       loader: null,
       loading: false,
      //form


      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'El nombre es requerido',
        v => (v && v.length <= 10) || 'El nombre debe ser mayor a 3 caracteres',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'El E-mail debe ser valido ',
      ],
      phone: '',
      phoneRules: [
        v => !!v || 'El telefono es requerido',
        v => (v && v.length <= 10) || 'El telefono debe ser mayor a 8 caracteres',
      ],
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: true,
    }),

    methods: {
      validate () {

        const validated = this.$refs.form.validate()
        if(validated==true){
          this.registerUser();
           
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },

       registerUser(){

          this.overlay= true;
          let userRegister = { 
            input_firstname: this.name, 
            input_email: this.email,
            input_phone: this.phone

          }
         // axios.post('http://webform.pw.com/api/registro/', userRegister)
        axios.post('https://sorteo.acpblindaje.mx/admin/api/registro/', userRegister)
          .then(response => {
               
                if(response.data.status == 'success'){
                  this.text= response.data.message;
                  this.snackbar = true;
                //  this.reset();
             

                this.userdata =  response.data.userdata;
                console.log(this.userdata);

                this.$router.push({ 
                  path: '/registro', 
                  name:'registro',
                  replace: true ,
                  params: { userdata: this.userdata}
                });
                }else{
                  this.text= response.data.message;
                  this.snackbar = true;
                     this.overlay=false;

                }
            }).catch(function (error) {
                console.error(error);
                this.snackbar = true;
            });



          
      },
      showAlert(responseText){
          this.text = responseText;
          this.snackbar =true;
      }
    },
     watch: {
     
    },

  }
</script>
